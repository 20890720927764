import UserInfoField from "./user-info-field";
import UserActionField from './user-action-field';
import UserContactField from './user-contact-field';
import UserTopPostsField from './user-post-top-field';



const UserFields = {
    Info : UserInfoField,
    Action: UserActionField,
    Contact: UserContactField,
    TopPosts: UserTopPostsField
}

export default UserFields;