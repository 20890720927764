import { gql } from '@apollo/client';


export const GET_ME = gql`
  query GetMe {
    me {
      id
      status
      nickname
      name
      email
      locale
      role
      initials
      gender
      avatar
      about
    }
  }
`;

export const GET_USERS = gql`
    query GetUsers(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryUsersOrderByRelationOrderByClause!],
        $where: QueryUsersWhereWhereConditions
    ){
        users(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
              id
              name
              initials
              nickname
              email
              status
              locale
              role
              gender
              avatar
              about
              itsMe
              me_following
              is_online
              last_active_date
              last_visit_at
              created_at
              updated_at
              sendPassword {
                id
                user_id
                send_user_id
                message
                created_at
              }
              meta_fields {
                  id,
                  meta_key,
                  meta_type,
                  meta_value
                }
            }
        }
    }
`;

export const GET_USERS_POSTS_TOP = gql`
    query GetUsers{
      users(
        first: 5
        page: 1
        orderBy: [{
          order: DESC
          user_counters: {
            aggregate: SUM
            column: PUBLIC_POSTS
          }
        } ]
       ) {
        data {
          id
          avatar
          name
          nickname
          initials
          user_counters {
            public_posts
          }
        }
      }
   }
`;




export const GET_USERS_CURSOR = gql`
    query UsersCursor(
        $text: String
        $where: QueryUsersCursorWhereWhereConditions
        $first: Int
        $after: String
    ) {
        usersCursor(
        text: $text
        where: $where
        first: $first
        after: $after
        ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
            total
            count
            currentPage
            lastPage
        }
        edges {
            cursor
              node {
                id
                name
                role
             }
           }
        }
    }
`;


export const GET_USER = gql`
    query GetUser(
        $id: ID,
    ) {
        user(
            id: $id,
        ){
          id
          name
          initials
          nickname
          email
          status
          locale
          role
          gender
          avatar
          about
          itsMe
          me_following
          is_online
          last_active_date
          last_visit_at
          created_at
          updated_at
          sendPassword {
            id
            user_id
            send_user_id
            message
            created_at
          }
          meta_fields {
              id,
              meta_key,
              meta_type,
              meta_value
            }
        }
    }
`;
