const english = {
    'USER.Button_Text_Create': 'Create user',
    'USER.Button_Text_Delete': 'Delete User',
    'POST.Button_Text_Delete': 'Delete Post',
    'TABLES.Column_Title_ID': 'ID',
    'TABLES.Column_Title_Title': 'Title',
    'TABLES.Column_Title_Author': 'Author',
    'TABLES.Column_Title_Likes': 'Likes',
    'TABLES.Column_Title_Comments': 'Comments',
    'TABLES.Column_Title_Media': 'Media',
    'TABLES.Column_Title_Attachments': 'Attachments',
    'TABLES.Column_Title_Name': 'Name',
    'TABLES.Column_Title_Phone': 'Phone',
    'TABLES.Column_Title_Email': 'Email',
    'TABLES.Column_Title_User': 'User',
    'TABLES.Column_Title_Type': 'Type',
    'TABLES.Column_Title_Message': 'Message',
    'TABLES.Column_Title_Label': 'Label',
    'TABLES.Column_Title_Posts': 'Posts',
    'TABLES.Column_Title_PostTitle': 'Post title',
    'TABLES.Column_Title_Followers': 'Followers',
    'TABLES.Column_Title_CreateDate': 'Create Date',
    'TABLES.Column_Sorting_Tooltip_Ascending': 'Click to sort ascending',
    'TABLES.Column_Sorting_Tooltip_Descending': 'Click to sort descending',
    'TABLES.Column_Sorting_Tooltip_Cancel': 'Click to cancel sorting',
    'TABLES.Column_Title_CompanyName': 'Company name',
    'TABLES.Column_Title_City': 'City',
    'TABLES.Column_Title_Street': 'Street',
    'TABLES.Column_Title_ZipCode': 'Zip code',
    'TABLES.Column_Title_Region': 'Region',
    'TABLES.Column_Title_District': 'District',
    'TABLES.Column_Title_FullName': 'Full name',
    'TABLES.Column_Title_Username': 'User name',
    'TABLES.Column_Title_Contacts': 'Contacts',
    'TABLES.Column_Title_Role': 'Role',
    'TABLES.Column_Title_Status': 'Status',
    'TABLES.Column_Title_Total': 'Total',
    'TABLES.Column_Title_Year': 'Year',
    'TABLES.Column_Title_FileName': 'file name',
    'TABLES.Column_Title_Description': 'description',
    'FORM_RULES.Min_NewPassword': 'Password must be at least { minLength } characters',
    'FORM_RULES.Required_NewPassword': 'Please input new Password',
    'FORM_RULES.Required_ConfirmPassword': 'Please confirm new password',
    'FORM_RULES.Required_ConfirmPasswordMatch': 'The entered passworts do not match.',
    'FORM_RULES.Required_Name': 'Please input name',
    'FORM_RULES.Required_Surname': 'Please input surname',
    'FORM_RULES.Required_Email': 'The input is not valid E-mail',
    'FORM_RULES.Required_Choose': 'Please Choose',
    'FORM_RULES.Required_Password': 'Please input your Password!',
    'FORM_RULES.Required_FullName': 'Please input your Full name',
    'FORM_RULES.Required_FullNameMinChar': 'Full name must be at least 3 characters',
    'FORM_RULES.Required_UserName': 'User name',
    'FORM_RULES.Required_CompanyName': 'Company name',
    'FORM_RULES.ContactPhone': 'May contain only digits, "+", "-", " "',
    'FORM_RULES.Email': 'The input is not valid E-mail',
    'FORMS.Input_Label_Password': 'Password',
    'FORMS.Input_Label_Login': 'login',
    'FORMS.Input_Label_NewPassword': 'New password',
    'FORMS.Input_Label_ConfirmPassword': 'Confirm new password',
    'FORMS.Input_Label_Language': 'Language',
    'FORMS.Input_Label_Name': 'Name',
    'FORMS.Input_Label_UserName': 'User name',
    'FORMS.Input_Label_Surname': 'Surname',
    'FORMS.Input_Label_Phone': 'Phone',
    'FORMS.Input_Label_Email': 'Email',
    'FORMS.Input_Label_Attachment': 'Attachment',
    'FORMS.Input_Label_Title': 'title',
    'FORMS.Input_Label_CollectionName': 'Collection name',
    'FORMS.Input_Label_CollectionUniqName': 'Collection unique name',
    'FORMS.Input_Label_Number': 'number',
    'FORMS.Input_Label_Time': 'time',
    'FORMS.Input_Label_Description': 'description',
    'FORMS.Input_Label_ChooseType': 'Choose type',
    'FORMS.Input_Label_Status': 'Status',
    'FORMS.Input_Label_ChooseStatus': 'Choose status',
    'FORMS.Input_Label_CompanyName': 'Company name',
    'FORMS.Input_Label_City': 'City',
    'FORMS.Input_Label_ZipCode': 'Zip code',
    'FORMS.Input_Label_Street': 'Street',
    'FORMS.Input_Label_Region': 'Region',
    'FORMS.Input_Label_District': 'District',
    'FORMS.Input_Label_Gender': 'Gender',
    'FORMS.Input_Label_Role': 'Role',
    'FORMS.Input_Label_Salutation': 'Salutation',
    'FORMS.Input_Label_JobType': 'Job type',
    'FORMS.Input_Label_Year': 'Year',
    'FORMS.Input_Label_User': 'User',
    'FORMS.Input_Label_Author': 'Author',
    'FORMS.Input_Label_CreatedAt': 'Created at',
    'FORMS.Input_Label_ActivationByEmail': 'Account activation by email',
    'FORMS.Input_Label_RegistrationByEmail': 'Registration by email',
    'FORMS.Input_Label_AllowedEmail': 'Allowed email',
    'FORMS.Input_Label_AllowedPersonalEmail': 'Allowed personal email',
    'FORMS.Input_Label_ContactPhone': 'Contact phone',
    'FORMS.Input_Label_ContactEmail': 'Contact E-Mail',
    'FORMS.Input_Label_Department': 'Department',
    'FORMS.Input_Label_JobDescription': 'Job description',
    'FORMS.Input_Label_WorkingOn': 'Working on',
    'FORMS.Title_SignIn': 'Sign in',
    'FORMS.Title_ForgotPassword': 'Forgot password',
    'FORMS.Title_ChangePassword': 'Change password',
    'FORMS.Title_SendPassword': 'Send password',
    'FORMS.Title_AddUsers': 'Add users',
    'FORMS.Title_Text_Filters': 'Filters',
    'FORMS.Title_AddFile': 'Add file',
    'FORMS.Title_EditFile': 'Edit file',
    'FORMS.Title_ChooseUser': 'Choose user',
    'FORMS.Select_OptionLabel_English': 'English',
    'FORMS.Select_OptionLabel_Deutsch': 'Deutsch',
    'FORMS.Select_OptionLabel_Male': 'Male',
    'FORMS.Select_OptionLabel_Female': 'Female',
    'FORMS.Select_OptionLabel_User': 'User',
    'FORMS.Select_OptionLabel_Moderator': 'Moderator',
    'FORMS.Select_OptionLabel_Admin': 'Admin',
    'FORMS.Select_OptionLabel_Mrs': 'Mrs.',
    'FORMS.Select_OptionLabel_Mr': 'Mr.',
    'FORMS.Select_OptionLabel_Active': 'Active',
    'FORMS.Select_OptionLabel_Inactive': 'Inactive',
    'FORMS.Select_OptionLabel_Block': 'Block',
    'FORMS.Select_OptionLabel_Public': 'Public',
    'FORMS.Select_OptionLabel_Trash': 'Trash',
    'FORMS.Select_OptionLabel_Private': 'Private',
    'FORMS.Select_OptionLabel_Draft': 'Draft',
    'FORMS.Select_OptionLabel_CSellingOwner': 'Co-selling owner',
    'FORMS.Select_OptionLabel_BranchManager': 'Branch manager',
    'FORMS.Select_OptionLabel_SalesManager': 'Sales Manager',
    'FORMS.Select_OptionLabel_CertifiedCarSalesman': 'Certified car salesman',
    'FORMS.Select_OptionLabel_Salesperson': 'Salesperson',
    'FORMS.Select_OptionLabel_ServiceConsultant': 'Service consultant',
    'FORMS.Select_OptionLabel_Management': "Management",
    'FORMS.Select_OptionLabel_ITManagement': "IT Management",
    'FORMS.Select_OptionLabel_FinancialAccounting': "Financial Accounting",
    'FORMS.Select_OptionLabel_LegalCounsel': "Legal Counsel",
    'FORMS.Select_OptionLabel_MarketingAndSales': "Marketing and Sales",
    'FORMS.Select_OptionLabel_SystemStandConstruction': "System Stand Construction",
    'FORMS.Select_OptionLabel_IndividualStandConstruction': "Individual Stand Construction",
    'FORMS.Select_OptionLabel_WarehousingAndLogistics': "Warehouse and Logistics",
    'FORMS.Select_OptionLabel_ConstructionManagement': "Technical management",
    'FORMS.Select_OptionLabel_AssemblyAndTransport': "Assembly and transport",
    'FORMS.Select_OptionLabel_CentralPurchasing' : "Central purchasing",
    'FORMS.Select_OptionLabel_Secretariat' : "Secretariat",
    'FORMS.Select_OptionLabel_Personnel': "Personnel",
    'FORMS.Select_OptionLabel_CEO' : "CEO",
    'FORMS.Select_OptionLabel_CTO' : "CTO",
    'FORMS.Select_OptionLabel_PM' : "Project Manager",
    'FORMS.Select_OptionLabel_PMCalculator' : "Project Manager/Calculator",
    'FORMS.Select_OptionLabel_PMCADDraftsman' : "Project manager/ CAD draftsman",
    'FORMS.Select_OptionLabel_CADDraftsman' : "CAD draftsman",
    'FORMS.Select_OptionLabel_ProjectAssistant' : "Project Assistant",
    'FORMS.Select_OptionLabel_DevelopmentTeamLead' : "Development team lead",
    'FORMS.Select_OptionLabel_SeniorBackendDeveloper' : "Senior backend developer",
    'FORMS.Select_OptionLabel_SoftwareDeveloper' : "Software developer",
    'FORMS.Select_OptionLabel_SystemAdministrator' : "System administrator",
    'FORMS.Select_OptionLabel_HeadSystemsStandConstruction'   : "Head of systems stand construction",
    'FORMS.Select_OptionLabel_HeadIndividualStandConstruction' : "Head of individual Stand construction",
    'FORMS.Select_OptionLabel_LogisticsEmployee' : "Logistics employee",
    'FORMS.Select_OptionLabel_FinancialAccountantReceivable' : "Financial accountant / Accounts receivable",
    'FORMS.Select_OptionLabel_FinancialAccountantPayable' : "Financial accountant / Accounts Payable",
    'FORMS.Select_OptionLabel_FinancialAccountantHR' : "Financial accountant / HR",
    'FORMS.Select_OptionLabel_SalesMarketing' : "Sales & Marketing",
    'FORMS.Select_OptionLabel_Clerk' : "Clerk",
    'FORMS.Select_OptionLabel_Secretary' : "Secretary",
    'FORMS.Input_Placeholder_Email': 'Email',
    'FORMS.Input_Placeholder_Choose': 'Please Choose',
    'FORMS.Input_Placeholder_Name': 'Name',
    'FORMS.Input_Placeholder_SelectDate': 'Select date',
    'FORMS.Input_Placeholder_ShortDescription': 'Enter short description',
    'FORMS.Input_Placeholder_ChooseStatus': 'Choose status',
    'FORMS.Input_Placeholder_ChooseType': 'Choose type',
    'FORMS.Input_Placeholder_NotSelected': 'Not selected',
    'FORMS.Input_Placeholder_Password': 'Password',
    'FORMS.Input_Label_DateBirth': 'Date of birth',
    'FORMS.Input_Label_DateEntrance': 'Date of entrance',
    'FORMS.Input_Placeholder_Year': 'Please Choose Year',
    'FORMS.Input_Placeholder_StartDate': 'Start date',
    'FORMS.Input_Placeholder_EndDate': 'End date',
    'FORMS.Button_Text_ApplyFilters': 'Apply filters',
    'FORMS.Button_Text_ResetFilters': 'Reset filters',
    'FORMS.Button_Text_UpdateCount': 'Update count',
    'SEARCH.Input_Placeholder_Table_User': 'Search for name, email, phone',
    'SEARCH.Input_Placeholder_Table_Title': 'Search for title',
    'SEARCH.Input_Placeholder_MultiSelect': 'Search',
    'SEARCH.Input_Placeholder_User': 'Search for users',
    'GLOBAL.Button_Text_Save': 'Save',
    'GLOBAL.Button_Text_Send': 'Send',
    'GLOBAL.Button_Text_SaveSend': 'Save and send',
    'GLOBAL.Button_Text_SaveChanges': 'Save changes',
    'GLOBAL.Button_Text_Edit': 'Edit',
    'GLOBAL.Button_Text_Update': 'update',
    'GLOBAL.Button_Text_Reimport': 'reimport',
    'GLOBAL.Button_Text_Cancel': 'Cancel',
    'GLOBAL.Button_Text_Delete': 'Delete',
    'GLOBAL.Button_Text_Remove': 'Remove',
    'GLOBAL.Button_Text_Confirm': 'Confirm',
    'GLOBAL.Button_Text_AddFile': 'Add file',
    'GLOBAL.Button_Text_UploadFile': 'Upload file',
    'GLOBAL.Button_Text_Login': 'Login',
    'GLOBAL.Button_Text_BackHome': 'Back Home',
    'GLOBAL.Button_Text_Actions': 'Actions',
    'GLOBAL.Modal_Text_UserSendPass': 'Are you sure you want to send a{ br }new password?',
    'GLOBAL.Modal_Text_RemoveText': 'Are you sure you want to remove the { deleteObjectType }?',
    'GLOBAL.Modal_Text_DeleteText': 'Are you sure you want to delete the { deleteObjectType }?',
    'GLOBAL.Link_Text_Download': 'Download',
    'GLOBAL.Link_Text_Unassigned': 'unassigned',
    'GLOBAL.Link_Text_Assigned': 'Assigned',
    'GLOBAL.Text_NoData': 'No Data',
    'GLOBAL.Text_Status_Disabled': 'Disabled',
    'GLOBAL.Text_Status_ChangePassUser': 'Password sent by { user } at { dateTime }',
    'GLOBAL.Modal_Text_ResetFilters': 'Reset filters',
    'ENTITY.Modal_Title_User': 'user',
    'ENTITY.Modal_Title_Post': 'post',
    'ENTITY.Modal_Title_Document': 'document',
    'ENTITY.Modal_Title_Comment': 'comment',
    'MODAL.Button_Text_Choose': 'Choose',
    'MODAL.Button_Text_AddUser': 'Add user',
    'MODAL.Button_Text_NotSelected': 'Not selected',
    'MODAL.Button_Text_NotAssigned': 'Not assigned',
    'MENUS.Label_Dashboard': 'dashboard',
    'MENUS.Label_Users': 'users',
    'MENUS.Label_Posts': 'posts',
    'MENUS.Label_Analytics': 'analytics',
    'MENUS.Label_Collections': 'collections',
    'MENUS.Label_Hashtags': 'hashtags',
    'MENUS.Label_Settings': 'settings',
    'MENUS_DROP.Label_Block': 'Block',
    'MENUS_DROP.Label_Active': 'Active',
    'MENUS_DROP.Label_MyProfile': 'My profile',
    'MENUS_DROP.Label_Logout': 'Logout',
    'MENUS_DROP.Label_SendPass': 'Send password',
    'MENUS_DROP.Label_Edit': 'Edit',
    'MENUS_DROP.Label_View': 'View',
    'MENUS_DROP.Label_Delete': 'Delete',
    'MENUS_DROP.Label_Mark': 'Mark',
    'MENUS_DROP.Label_InProgress': 'in progress',
    'MENUS_DROP.Label_Overview': 'Overview',
    'MENUS_DROP.Label_ViewPosts': 'View posts',
    'MENUS_DROP.Label_ViewFollowers': 'View followers',
    'MENUS_DROP.Label_Download': 'Download',
    'NAVBAR_PAGES.Label_Overview': 'overview',
    'NAVBAR_PAGES.Label_JobProfile': 'Job Profile',
    'NAVBAR_PAGES.Label_Users': 'users',
    'NAVBAR_PAGES.Label_Sales': 'sales',
    'NAVBAR_PAGES.Label_Settings': 'settings',
    'NAVBAR_PAGES.Label_Counters': 'counters',
    'NAVBAR_PAGES.Label_EmailRules': 'email rules',
    'NAVBAR_PAGES.Label_Media': 'media',
    'NAVBAR_PAGES.Label_Attachments': 'attachments',
    'NAVBAR_PAGES.Label_Comments': 'comments',
    'NAVBAR_PAGES.Label_Posts': 'posts',
    'NAVBAR_PAGES.Label_Followers': 'followers',
    'NAVBAR_PAGES.Label_AllComments': 'all comments',
    'BREADCRUMBS.Label_Posts': 'posts',
    'BREADCRUMBS.Label_Collections': 'collections',
    'BREADCRUMBS.Label_Hashtags': 'hashtags',
    'BREADCRUMBS.Label_User': 'user',
    'BREADCRUMBS.Label_Users': 'users',
    'BREADCRUMBS.Label_Analytics': 'analytics',
    'BREADCRUMBS.Label_Sales': 'sales',
    'BREADCRUMBS.Label_Settings': 'settings',
    'BREADCRUMBS.Label_Counters': 'counters',
    'BREADCRUMBS.Label_Followers': 'followers',
    'BREADCRUMBS.Label_Attachments': 'attachments',
    'BREADCRUMBS.Label_Comments': 'comments',
    'BREADCRUMBS.Label_Media': 'media',
    'BREADCRUMBS.Label_Create': 'create',
    'BREADCRUMBS.Label_JobProfile': 'job profile',
    'PAGES.Title_Dashboard': 'Dashboard',
    'PAGES.Title_Users': 'Users',
    'PAGES.Title_Posts': 'Posts',
    'PAGES.Title_AllComments': 'All Comments',
    'PAGES.Title_Collections': 'Collections',
    'PAGES.Title_Hashtags': 'Hashtags',
    'PAGES.Title_Analytics': 'Analytics',
    'PAGES.Title_Settings': 'Settings',
    'PAGES.Title_CreateUser': 'Create User',
    'PAGES.Title_404': 'Sorry, the page you visited does not exist.',
    'PAGES.SubTitle_TopPosts': 'Top posts:',
    'PAGES.SubTitle_TopAuthors': 'The most posting authors:',
    'PAGES.Text_NoTopAuthors': 'There are no posting authors yet',
    'PAGES.Text_NoTopPosts': 'There are no top posts yet',
    'DASHBOARD.Chart_Title_AttachmentsCount': 'Attachments count',
    'DASHBOARD.Chart_Title_CollectionsCount': 'Collections count',
    'DASHBOARD.Chart_Title_CommentsCount': 'Comments count',
    'DASHBOARD.Chart_Title_HashtagsCount': 'Hashtags count',
    'DASHBOARD.Chart_Title_MediaCount': 'Media count',
    'DASHBOARD.Chart_Title_PostsCount': 'Posts count',
    'DASHBOARD.Chart_Title_UsersCount': 'Users count',
    'DASHBOARD.Chart_Count_Total': 'Total',
    'DATE.Time_Zone': 'en-GB',
    'CLOUDINARY_REPORT_Titles': 'Cloudinary reports',
    'CLOUDINARY_REPORT_Title' : 'Cloudinary report',
    'GL_Text_OnThePage'       : 'on the page',
    'GL_Text_Total'           : 'total'
};

export default english;