import UserAddСheckboxSelect from "./user-add-сheckbox-select";
import UserLanguageSelect from "./user-language-select";
import UserGenderSelect from "./user-gender-select";
import UserRoleSelect from "./user-role-select";
import UserStatusSelect from "./user-status-select";
import UserAddRadioSelect from "./user-add-radio-select";



const UserFormItems = {
    AddUserСheckboxSelect: UserAddСheckboxSelect,
    AddUserRadioSelect: UserAddRadioSelect,
    LanguageSelect: UserLanguageSelect,
    GenderSelect: UserGenderSelect,
    RoleSelect: UserRoleSelect,
    StatusSelect: UserStatusSelect,
};

export default UserFormItems;