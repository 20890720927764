import React from "react";
import { Button } from "antd";
import UserFormItems from '../fields'
import { Localize } from "components/service";
import { initialValFormatted } from "utils/helper-functions";


const UserFiltersForm = ({ action, usersFilters, setCount, setFilters, modelFilters, setFilterList, filterList, gqlBuilderWhere, formFieldsHide, form }) => {


    const onChangeSetFilter = (e, name, value) => {

        if (e)
        {
            name = e.target.name
            value = e.target.value
        }

        value.toString().length > 0 ?

            setFilterList({
                ...filterList,
                [ name ]: {
                    column: name,
                    operator: "EQ",
                    value: value
                }
            }) : setFilterList({ ...filterList, [ name ]: {} });
    }


    return (

        <div className="ant-form ant-form-vertical filters-form">


            { formFieldsHide.includes('STATUS') ? null :
                <UserFormItems.StatusSelect
                    initialValue={ initialValFormatted(usersFilters, 'STATUS') }
                    name="STATUS"
                    form={ form }
                    onChangeSetFilter={ onChangeSetFilter } />
            }

            { formFieldsHide.includes('ROLE') ? null :
                <UserFormItems.RoleSelect
                    initialValue={ initialValFormatted(usersFilters, 'ROLE') }
                    name="ROLE"
                    form={ form }
                    onChangeSetFilter={ onChangeSetFilter } />
            }

            <div className="form-btn-holder">
                <Button
                    type="primary"
                    htmlType="submit"
                    onClick={ () => {
                        setFilters(gqlBuilderWhere({ ...usersFilters, ...filterList }));
                        setCount({ ...usersFilters, ...filterList });
                        action();
                    } }
                >
                    <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </Button>
                <Button
                    className="ant-btn-secondary bg-light-gray btn-right"
                    htmlType="submit"
                    onClick={ () => {
                        setFilterList({});
                        setCount({});
                        setFilters(gqlBuilderWhere({}));
                        localStorage.removeItem(modelFilters);
                    } }
                >
                    <Localize>FORMS.Button_Text_ResetFilters</Localize>
                </Button>
            </div>
        </div>
    )
}

export default UserFiltersForm;